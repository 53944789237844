// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/workspace/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/workspace/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cookie-policy-js": () => import("/workspace/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-faqs-js": () => import("/workspace/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("/workspace/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-previous-consultation-material-js": () => import("/workspace/src/pages/previous-consultation-material.js" /* webpackChunkName: "component---src-pages-previous-consultation-material-js" */),
  "component---src-pages-upcoming-meetings-js": () => import("/workspace/src/pages/upcoming-meetings.js" /* webpackChunkName: "component---src-pages-upcoming-meetings-js" */)
}

